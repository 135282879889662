import { Controller } from '@hotwired/stimulus';
import { disableShareButton, enableShareButton } from '../helpers/share_button';
export default class default_1 extends Controller {
    constructor() {
        super(...arguments);
        this.edit = (event) => {
            event.preventDefault();
            disableShareButton();
            const source = event.currentTarget.getAttribute('href');
            fetch(source, {
                credentials: 'same-origin',
            })
                .then((response) => response.text())
                .then((text) => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(text, 'text/html');
                const form = doc.body.querySelector('main form');
                if (form) {
                    this.replacedHTML = this.element.innerHTML;
                    this.element.innerHTML = '';
                    this.element.appendChild(form);
                    form.addEventListener('submit', this.submit);
                }
            });
        };
        this.submit = (event) => {
            event.preventDefault();
            const target = event.currentTarget;
            if (!target.checkValidity()) {
                return;
            }
            this.element.querySelector("input[type='submit'], button[type='submit']").setAttribute('disabled', 'disabled');
            const formData = new FormData(target);
            fetch(target.action, {
                method: 'POST',
                body: formData,
                credentials: 'same-origin',
            })
                .then((resp) => {
                return resp.text();
            })
                .then((text) => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(text, 'text/html');
                const replacement = doc.body.querySelector(`.${this.data.get('item')}`);
                if (replacement) {
                    this.element.innerHTML = replacement.innerHTML;
                    this.replacedHTML = '';
                    enableShareButton();
                }
                else {
                    const form = doc.body.querySelector('main form');
                    this.element.innerHTML = '';
                    this.element.appendChild(form);
                    form.addEventListener('submit', this.submit);
                }
                this.notifyUpdate();
            });
        };
        this.notifyUpdate = () => {
            const event = document.createEvent('CustomEvent');
            event.initCustomEvent('list-updated', true, true, null);
            this.element.dispatchEvent(event);
        };
        this.reset = (event) => {
            if (this.replacedHTML) {
                event.preventDefault();
                enableShareButton();
                this.element.innerHTML = this.replacedHTML;
            }
        };
    }
}
default_1.targets = ['edit'];
