import { Controller } from '@hotwired/stimulus';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { renderer } from '../helpers/renderer';
export default class extends Controller {
    constructor() {
        super(...arguments);
        this.click = (event) => {
            event.preventDefault();
            document.addEventListener('keydown', this.keyDown);
            document.body.append(this.zoomOverlay());
        };
        this.keyDown = (event) => {
            if (event.key === 'Escape') {
                event.preventDefault();
                this.closeDialog();
            }
        };
        this.close = (event) => {
            if (event.target.nodeName === 'A') {
                return;
            }
            event.preventDefault();
            this.closeDialog();
        };
        this.closeDialog = () => {
            document.removeEventListener('keydown', this.keyDown);
            const element = document.body.querySelector('.image-zoom');
            if (element) {
                element.remove();
            }
        };
    }
    connect() {
        this.element.classList.add('image-zoom-click');
        this.imageAlt = this.element.getAttribute('alt');
    }
    zoomOverlay() {
        return (renderer.create("div", { class: "image-zoom", onclick: this.close },
            renderer.create("div", { class: "image-zoom-image" },
                renderer.create("img", { src: this.data.get('url'), alt: this.imageAlt })),
            renderer.create("button", { class: "image-zoom-close", type: "button", onclick: this.close }, "x"),
            renderer.create("div", { class: "image-zoom-target" }, this.data.get('target') && this.data.get('target').length > 0 && (renderer.create("a", { href: this.data.get('target'), target: "_blank", rel: "noopener noreferrer" }, this.data.get('url-display'))))));
    }
}
