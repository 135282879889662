import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
    connect() {
        const year = new Date().getFullYear();
        const disabled = window.localStorage.getItem(`christmas-banner-${year}`);
        if (disabled === 'true') {
            this.element.remove();
        }
        else {
            this.element.classList.remove('curtain');
        }
    }
    hide() {
        const height = this.element.clientHeight;
        this.element.style.height = `${height}px`;
        setTimeout(() => {
            this.element.classList.add('hidden');
        }, 100);
        const year = new Date().getFullYear();
        window.localStorage.setItem(`christmas-banner-${year}`, 'true');
    }
}
