import {Controller} from '@hotwired/stimulus';
import anime from 'animejs';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import {renderer} from '../helpers/renderer';
import {forEach} from '../helpers/forEach';
import {disableShareButton, enableShareButton} from '../helpers/share_button';
/* eslint-disable no-unused-vars */
import Spinner from '../components/spinner';
import {validateIsbn} from '../helpers/isbn';
import {i18next} from '../i18next.js';
import {raw} from '../helpers/raw';

export default class extends Controller {
  mode = 'edit';

  imageWidth = 0;

  imageHeight = 0;

  connect() {
    this.storeTargetWidths();
    this.mode = 'import';
    this.element.classList.add('list--entry--import--mode');
    this.removeImportButton();
    this.replaceDisplayText();
    this.addCancelButton();
    this.disableAddButton(true);
    this.addClickToPlus();
    window.onresize = () => {
      if (this.mode === 'edit') {
        this.storeTargetWidths();
      } else {
        this.element.classList.add('list--entry--edit--mode');
        this.element.classList.remove('list--entry--import--mode');
        this.storeTargetWidths();
        this.element.classList.remove('list--entry--edit--mode');
        this.element.classList.add('list--entry--import--mode');
      }
    };
  }

  disableAddButton(disabled) {
    const button = this.element.querySelector("button[type='submit']");
    button.disabled = disabled ? 'disabled' : null;
  }

  addClickToPlus() {
    const button = this.element.querySelector('.list--entry--add');
    button.addEventListener('click', (event) => {
      event.preventDefault();
      this.switchToEditMode();
    });
  }

  storeTargetWidths() {
    const imageElement = document.querySelector('.list--entry--image');
    if (imageElement) {
      imageElement.style.width = null;
      imageElement.style.height = null;
      this.imageWidth = imageElement.clientWidth;
      this.imageHeight = imageElement.clientHeight;
    }
  }

  replaceDisplayText() {
    this.swapAttribute(document.querySelector('.list--entry--name'), 'placeholder', 'data-url-placeholder');
    this.swapText(document.querySelector("label[for='list_entry_name']"), 'data-url-name');
  }

  swapAttribute(element, attribute, swappedAttribue) {
    const attributeValue = element.getAttribute(attribute);
    const newAttributeValue = element.getAttribute(swappedAttribue);
    element.setAttribute(attribute, newAttributeValue);
    element.setAttribute(swappedAttribue, attributeValue);
  }

  swapText(element, attribute) {
    const textValue = element.textContent;
    const newTextValue = element.getAttribute(attribute);
    element.innerHTML = newTextValue;
    element.setAttribute(attribute, textValue);
  }

  removeImportButton() {
    const items = document.querySelectorAll('.list--item--import', this.element);
    forEach(items, (element) => {
      element.remove();
    });
  }

  addCancelButton() {
    if (document.querySelector('.list--entry--buttons--cancel') === null) {
      const buttons = document.querySelector('.list--entry--buttons');
      buttons.innerHTML += `<a href='#' class='list--entry--buttons--cancel' data-action='add-wish#cancel'>${this.data.get(
        'cancel'
      )}</a>`;
    }
  }

  cancel(event) {
    event.preventDefault();
    enableShareButton();
    this.disableAddButton(true);
    this.storeTargetWidths();
    this.replaceDisplayText();
    this.mode = 'import';
    this.element.classList.remove('list--entry--edit--mode');
    this.element.classList.add('list--entry--import--mode');
    this.resetInputFields();
  }

  resetInputFields() {
    const hintElement = this.element.querySelector('.js-list-entry-help');
    if (hintElement) {
      hintElement.remove();
    }
    this.element.querySelector("input[name='list_entry[image]']").value = '';
    this.element.querySelector('.list--entry--image--preview').src = '/images/gray.png';
    this.element.querySelector('textarea').value = '';
    this.element.querySelector('textarea').style.height = 'auto';
    this.element.querySelector('.list--entry--name').value = '';
    this.element.querySelector('.list--entry--price--value').value = null;
    this.element.querySelector('#list_entry_price_currency').selectedIndex = 0;
    this.element.querySelector("input[name='list_entry[isbn]']").value = '';
    this.element.querySelector("input[name='list_entry[url]']").value = '';
    this.element.querySelector("input[name='list_entry[url]']").dispatchEvent(new Event('change'));
  }

  submit(event) {
    if (this.mode === 'import') {
      event.preventDefault();
      const text = document.querySelector('.list--entry--name').value;
      const url = this.extractURL(text);
      if (url) {
        this.fetchMetadataForUrl(url);
      } else if (validateIsbn(text)) {
        this.fetchMetadataForIsbn(text);
      } else {
        this.switchToEditMode();
      }
    }
  }

  getWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }

  startAnimation() {
    if (this.getWidth() < 50 * 16) {
      this.transformWithoutAnimation();
    } else {
      this.doAnimation();
    }
  }

  transformWithoutAnimation() {
    setTimeout(() => {
      const position = document.querySelector('.list--entry--name').getBoundingClientRect().top;
      window.scrollTo(0, position + window.scrollY - 120);
    }, 50);
  }

  doAnimation() {
    const target = document.getElementsByClassName('list--entry--image')[0];

    target.style.overflow = 'hidden';
    target.style.opacity = 0;

    const otherElements = document.querySelectorAll(
      '.list--entry--isbn, .list--entry--price--row, .list--entry--link, .list--entry--buttons--cancel'
    );

    this.setOpacity(otherElements, 0);

    target.style.width = '0px';
    anime({
      targets: target,
      width: this.imageWidth,
      height: this.imageHeight,
      easing: 'easeInOutQuad',
      opacity: 1,
      duration: 300,
    });

    anime({
      targets: otherElements,
      opacity: 1,
      duration: 500,
      easing: 'easeInOutQuad',
    });
  }

  scrollTo(element) {
    element.scrollIntoView &&
      element.scrollIntoView({
        behavior: 'smooth',
      });
  }

  setOpacity(elements, value) {
    forEach(elements, (element) => {
      element.style.opacity = value;
    });
  }

  switchToEditMode() {
    if (this.mode !== 'edit') {
      this.getLatestCsrfToken().then((token) => {
        const csrfTokenInput = this.element.querySelector("input[name='_csrf_token']");
        if (csrfTokenInput) {
          csrfTokenInput.value = token;
        }
      });

      this.mode = 'edit';
      this.element.classList.remove('list--entry--import--mode');
      this.element.classList.add('list--entry--edit--mode');
      disableShareButton();
      this.replaceDisplayText();
      this.startAnimation();
    }
  }

  async getLatestCsrfToken() {
    return fetch(window.location)
      .then((result) => {
        return result.text();
      })
      .then((html) => {
        let parser = new DOMParser();
        let doc = parser.parseFromString(html, 'text/html');
        return doc.querySelector("meta[name='csrf-token']").getAttribute('content');
      })
      .catch(() => {
        const csrfTokenInput = this.element.querySelector("input[name='_csrf_token']");
        return csrfTokenInput?.value || '';
      });
  }

  textUpdated(event) {
    this.disableAddButton(event.target.value.length === 0);
    if (
      this.mode === 'import' &&
      !this.isTypingUrl(event.target.value.trim()) &&
      !this.isTypingISBN(event.target.value.trim())
    ) {
      this.switchToEditMode();
    }
  }

  pasted(event) {
    setTimeout(() => {
      const text = event.target.value.trim();
      const url = this.extractURL(text);
      if (url) {
        this.fetchMetadataForUrl(url);
      } else if (validateIsbn(text)) {
        this.fetchMetadataForIsbn(text);
      }
    }, 100);
  }

  isTypingISBN(value) {
    const cleanedValue = value.replace(/[\s-]*/gi, '');

    if (cleanedValue.match(/^\d{1,13}[xX]?$/)) {
      return true;
    }

    return false;
  }

  isTypingUrl(value) {
    return this.startsWithProtocol(value) || this.isURL(value);
  }

  startsWithProtocol(url) {
    return 'http://'.startsWith(url) || 'https://'.startsWith(url);
  }

  isURL(text) {
    return (
      text.match(
        /^(http(s)?:\/\/)[-a-zA-Z0-9\x7f-\xff@:%._+~#=]{1,256}(\.[a-zA-Z0-9\u00a1-\uffff()]{1,6}\b([-a-zA-Z0-9\u00a1-\uffff()@:%_+.~#?&,//=]*))?$/
      ) !== null
    );
  }

  extractURL(text) {
    const match = text.match(
      /((?:http(?:s)?:\/\/)[-a-zA-Z0-9\x7f-\xff@:%._+~#=]{1,256}(?:\.[a-zA-Z0-9\u00a1-\uffff()]{1,6}\b(?:[-a-zA-Z0-9\u00a1-\uffff()@:%_+.~#?&,//=]*)))/
    );

    if (match) {
      return match[0];
    }
    return null;
  }

  fetchMetadataForUrl(url) {
    const spinner = this.element.appendChild(
      <div class="add-wish--spinner">
        <Spinner />
      </div>
    );
    fetch('/api/url-metadata', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify({url: url}),
    }).then((result) => {
      if (result.status === 200) {
        this.fillMetadataResult(result.json(), url);
        spinner.remove();
      } else {
        this.resetInputFields();

        const parent = this.element.querySelector('.list--entry--input');
        parent.prepend(<p class="js-list-entry-help warn-block">{raw(i18next.t('import.error'))}</p>);
        this.element.querySelector("input[name='list_entry[url]']").value = url;
        this.switchToEditMode();
        spinner.remove();
      }
    });
  }

  fillMetadataResult = (jsonData, url, isbn) => {
    jsonData.then((json) => {
      this.element.querySelector("input[name='list_entry[image]']").value = '';
      this.element.querySelector('.list--entry--image--preview').src = '/images/gray.png';
      this.element.querySelector('textarea').value = '';
      this.element.querySelector('.list--entry--name').value = json.name || json.title;

      if (json.price) {
        this.element.querySelector('.list--entry--price--value').value = json.price.value;
        this.element.querySelector('#list_entry_price_currency').value = json.price.currency;
      }

      if (json.isbn || isbn) {
        this.element.querySelector("input[name='list_entry[isbn]']").value = json.isbn || isbn;
      }

      const urlValue = json.url || url;
      if (urlValue) {
        this.element.querySelector("input[name='list_entry[url]']").value = urlValue.trim();
        this.element.querySelector("input[name='list_entry[url]']").dispatchEvent(new Event('change'));
      }

      this.switchToEditMode();

      if (json.image) {
        this.element.querySelector('.list--entry--image--preview').src = json.image_preview;

        this.element.querySelector("input[name='list_entry[image_url]']").value = this.cleanImageUrl(json.image);
      }
    });
  };

  cleanImageUrl = (imageUrl) => imageUrl.split('?')[0];

  fetchMetadataForIsbn(isbn) {
    const spinner = this.element.appendChild(
      <div class="add-wish--spinner">
        <Spinner />
      </div>
    );
    fetch('/api/isbn-metadata', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify({isbn: isbn.trim()}),
    }).then((result) => {
      if (result.status === 200) {
        this.fillMetadataResult(result.json(), null, isbn);
        spinner.remove();
      } else {
        this.switchToEditMode();
        spinner.remove();
      }
    });
  }
}
