import {Controller} from '@hotwired/stimulus';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import {renderer} from '../helpers/renderer';
import A11yDialog from 'a11y-dialog';
import anime from 'animejs';
import {i18next} from '../i18next.js';
import {buildHiddenInput} from '../helpers/forms';

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.deleteClicked);
  }

  deleteClicked = (event) => {
    event.preventDefault();

    this.dialogElement = document.getElementsByTagName('body')[0].appendChild(this.dialogHtml());

    this.dialog = new A11yDialog(this.dialogElement);
    this.dialog.show();
    this.dialog.on('hide', this.close);
    this.focusDelete();
  };

  focusDelete = () => {
    document.getElementsByClassName('js-btn-delete', this.dialogElement)[0].focus();
  };

  close = () => {
    const animation = anime({
      targets: this.dialogElement,
      opacity: 0,
      easing: 'easeInOutQuad',
      duration: 300,
    });
    animation.finished.then(() => {
      this.dialogElement.remove();
      this.dialog.destroy();
    });
  };

  confirmedDelete = () => {
    this.close();

    if (this.data.get('dom')) {
      this.deleteAndRemove();
    } else {
      this.delete();
    }
  };

  notifyUpdate = (element) => {
    const event = document.createEvent('CustomEvent');
    event.initCustomEvent('list-updated', true, true, null);
    element.dispatchEvent(event);
  };

  delete = () => {
    var to = this.element.getAttribute('data-to') || this.element.getAttribute('href');
    var method = buildHiddenInput('_method', this.element.getAttribute('data-method'));
    var csrf = buildHiddenInput('_csrf_token', this.element.getAttribute('data-csrf'));
    var form = document.createElement('form');
    var target = this.element.getAttribute('target');

    form.method = this.mapMethodToHttpVerb(this.element.getAttribute('data-method'));
    form.action = to;
    form.style.display = 'hidden';

    if (target) form.target = target;

    form.appendChild(csrf);
    form.appendChild(method);

    if (typeof URLSearchParams === 'function') {
      const searchParams = new URLSearchParams(to.split('?')[1]);

      Array.from(searchParams.entries()).forEach(([key, value]) => {
        form.appendChild(buildHiddenInput(key, value));
      });
    }

    document.body.appendChild(form);
    form.submit();
  };

  deleteAndRemove = () => {
    const data = new FormData();
    data.append('_method', this.element.getAttribute('data-method'));
    data.append('_csrf_token', this.element.getAttribute('data-csrf'));

    let method = this.mapMethodToHttpVerb(this.element.getAttribute('data-method'));

    fetch(this.element.getAttribute('href'), {
      method: method,
      body: data,
      credentials: 'same-origin',
    }).then(() => {
      this.animateRemove();
    });
  };

  mapMethodToHttpVerb = (method) => {
    switch (method) {
      case 'delete':
      case 'post':
      case 'patch':
      case 'put':
        return 'post';
      default:
        return 'get';
    }
  };

  animateRemove = () => {
    const element = document.querySelector(`.${this.data.get('dom')}`);
    if (element) {
      element.style.overflow = 'hidden';
      const animation = anime({
        targets: element,
        height: 9,
        opacity: 0,
        marginBottom: 0,
        easing: 'easeInOutQuad',
        duration: 300,
      });
      animation.finished.then(() => {
        const parent = element.parentNode;
        element.remove();
        this.notifyUpdate(parent);
      });
    }
  };

  dialogHtml = () => {
    return (
      <div id="delete-dialog" class="dialog" aria-labelledby="dialog-title">
        <div class="dialog-overlay" data-a11y-dialog-hide />
        <div role="dialog" class="dialog-content">
          <button type="button" class="dialog-close" data-a11y-dialog-hide aria-label={i18next.t('dialog.close')}>
            &times;
          </button>

          <h1 id="dialog-title">{this.data.get('title') || i18next.t('delete.title')}</h1>

          <p class="delete-text">{this.data.get('confirm')}</p>

          <div class="delete-buttons">
            <button type="button" class="btn--border" onclick={this.close}>
              {i18next.t('common.cancel')}
            </button>

            <button type="button" class="btn js-btn-delete" onclick={this.confirmedDelete}>
              {this.data.get('button') || i18next.t('delete.button')}
            </button>
          </div>
        </div>
      </div>
    );
  };
}
