import { Controller } from '@hotwired/stimulus';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { renderer } from '../helpers/renderer';
import Popup from '../helpers/popup';
import { i18next } from '../i18next.js';
import { forEach } from '../helpers/forEach';
import { replaceChildren } from '../helpers/replaceChildren';
export default class extends Controller {
    constructor() {
        super(...arguments);
        this.popup = null;
        this.popupController = null;
        this.formAction = null;
        this.formMethod = null;
        this.selectName = null;
        this.labelText = '';
        this.openSelectBox = () => {
            if (this.popupController) {
                this.popupController.removePopup();
            }
            else {
                this.showPopup();
            }
        };
        this.dialogClosePressed = () => {
            this.popupController.removePopup();
        };
        this.buttonClicked = (event, entry) => {
            event.preventDefault();
            const form = document.createElement('form');
            form.style.display = 'none';
            form.method = this.formMethod;
            form.action = this.formAction;
            const element = document.createElement('input');
            element.value = entry.value;
            element.name = this.selectName;
            form.appendChild(element);
            forEach(this.formHidden, (item) => {
                const hidden = document.createElement('input');
                hidden.value = item.value;
                hidden.name = item.name;
                form.appendChild(hidden);
            });
            document.body.appendChild(form);
            form.submit();
        };
        this.removePopup = () => {
            this.popupController = null;
        };
        this.selectButton = () => {
            return (renderer.create("button", { class: "enhanced-select--button", type: "button", onclick: this.openSelectBox, "aria-label": this.labelText }));
        };
    }
    connect() {
        this.formAction = this.element.getAttribute('action');
        this.formMethod = this.element.getAttribute('method');
        this.selectName = this.element.querySelector('select').getAttribute('name');
        this.labelText = this.element.querySelector('label').textContent;
        const items = Array.from(this.element.querySelectorAll('option'));
        this.selectEntries = [];
        forEach(items, (element) => {
            this.selectEntries.push({
                value: element.attributes.getNamedItem('value').value,
                text: element.textContent,
                selected: element.attributes.getNamedItem('selected') != null,
            });
        });
        const hidden = Array.from(this.element.querySelectorAll("input[type='hidden']"));
        this.formHidden = [];
        forEach(hidden, (element) => {
            this.formHidden.push({
                value: element.attributes.getNamedItem('value').value,
                name: element.attributes.getNamedItem('name').value,
            });
        });
        replaceChildren(this.element, this.selectButton());
        fetch(`/images/${this.data.get('icon')}`)
            .then((response) => response.text())
            .then((response) => {
            const button = this.element.querySelector('.enhanced-select--button');
            button.innerHTML = response;
        });
    }
    showPopup() {
        this.popup = this.element.insertAdjacentElement('afterend', renderer.create("div", { class: "popup" },
            renderer.create("div", { class: "enhanced-select" },
                renderer.create("div", { class: "enhanced-select--headline" }, this.labelText),
                renderer.create("button", { type: "button", class: "popup-close", "aria-label": i18next.t('dialog.close'), onclick: this.dialogClosePressed }, "\u00D7"),
                this.selectEntries.map((entry) => (renderer.create("button", { class: `enhanced-select--select ${entry.selected ? 'enhanced-select--selected' : ''}`, onclick: (event) => this.buttonClicked(event, entry) }, entry.text))))));
        this.popupController = new Popup(this.element, this.popup, {
            popupClosed: this.removePopup,
        });
    }
}
