import { Controller } from '@hotwired/stimulus';
import { i18next } from '../i18next.js';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { renderer } from '../helpers/renderer';
import { ResizeObserver } from 'resize-observer';
export default class extends Controller {
    constructor() {
        super(...arguments);
        this.collapsed = true;
        this.currentWidth = null;
        this.currentHeight = 0;
        this.reflowLayout = (force) => {
            const boxWidth = this.element.getBoundingClientRect().width;
            const boxHeight = this.element.getBoundingClientRect().height;
            const children = this.getChildren();
            if (force === true || boxWidth !== this.currentWidth || boxHeight > this.currentHeight) {
                this.currentWidth = boxWidth;
                if (boxHeight > this.currentHeight) {
                    this.currentHeight = boxHeight;
                }
                if (children.length > 0) {
                    const elementWidth = this.widthOfElement(children[0]);
                    const maxRowItems = Math.floor(boxWidth / elementWidth);
                    if (this.collapsed && children.length > maxRowItems) {
                        this.currentWidth = this.element.getBoundingClientRect().width;
                        this.hideOverflow(children, maxRowItems - 1);
                        this.addShowButton();
                    }
                }
            }
        };
        this.getChildren = () => Array.from(this.element.querySelectorAll('a'));
        this.widthOfElement = (element) => {
            const style = window.getComputedStyle(element);
            const margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
            return element.offsetWidth + margin;
        };
        this.hideOverflow = (elements, maxItems) => {
            for (let i = 0; i < elements.length; i += 1) {
                if (i > maxItems) {
                    elements[i].style.display = 'none';
                }
                else {
                    elements[i].style.display = 'inline-block';
                }
            }
        };
        this.removeShowButton = () => {
            this.removeElementsByClass(this.element, '.js-collapsable-show-items');
        };
        this.addShowButton = () => {
            this.removeShowButton();
            this.element.appendChild(this.renderShowButton());
        };
        this.addHideButton = () => {
            this.element.appendChild(this.renderHideButton());
        };
        this.removeElementsByClass = (element, className) => {
            element.querySelectorAll(className).forEach((child) => {
                child.remove();
            });
        };
        this.renderShowButton = () => {
            return (renderer.create("button", { type: "button", class: "collapsable js-collapsable-show-items", onclick: this.showItems },
                renderer.create("img", { src: "/images/down-simple.svg", alt: i18next.t('items.show'), title: i18next.t('items.show') })));
        };
        this.renderHideButton = () => {
            return (renderer.create("button", { type: "button", class: "collapsable js-collapsable-show-items", onclick: this.hideItems },
                renderer.create("img", { src: "/images/up-simple.svg", alt: i18next.t('items.hide'), title: i18next.t('items.hide') })));
        };
        this.hideItems = () => {
            this.collapsed = true;
            this.reflowLayout(true);
        };
        this.showItems = () => {
            this.collapsed = false;
            const children = this.getChildren();
            children.forEach((child) => {
                child.style.display = 'inline-block';
            });
            this.removeShowButton();
            this.addHideButton();
        };
    }
    connect() {
        const resizeObserver = new ResizeObserver(() => {
            this.reflowLayout(false);
        });
        setTimeout(() => {
            this.reflowLayout(true);
            resizeObserver.observe(this.element);
        }, 100);
    }
}
