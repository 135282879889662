import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
    connect() {
        const body = document.body;
        this.element.addEventListener('dragover', (e) => {
            e.preventDefault();
            body.classList.add('dragging');
        });
        this.element.addEventListener('dragleave', () => {
            body.classList.remove('dragging');
        });
        this.element.addEventListener('drop', (e) => {
            e.preventDefault();
            body.classList.remove('dragging');
        });
    }
}
