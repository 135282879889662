export default {
    claim: {
        error: 'Es gab ein Problem beim reservieren des Wunsches. Bitte lade die Seite neu.',
    },
    dialog: {
        close: 'Diesen Dialog schließen',
        copy: 'In der Zwischenablage',
    },
    delete: {
        title: 'Löschen?',
        button: 'Löschen',
    },
    common: {
        ok: 'OK',
        cancel: 'Abbrechen',
        close: 'Schließen',
    },
    cookieconsent: {
        header: 'Diese Seite nutzt Cookies!',
        message: 'Diese Seite nutzt Cookies um die Nutzung zu verbessern.',
        dismiss: 'Verstanden!',
        allow: 'Erlaube Cookies',
        deny: 'Ablehnen',
        link: 'Mehr dazu',
        policy: 'Cookie Richtlinie',
    },
    shareDialog: {
        shareList: 'Wunschliste teilen',
        title: 'Wunschliste teilen',
        sharingTitle: 'Für Schenkende',
        sharingExplanation: 'Mit diesem Link kann man deine Wunschliste ansehen und Geschenke reservieren. Für alle, die etwas schenken wollen.',
        shareLink: 'Link teilen',
        copyLink: 'Link kopieren',
        editingTitle: 'Für Dich',
        editingExplanation: 'Mit diesem Link kannst du deine Wunschliste bearbeiten und löschen.\n⚠️ Teile ihn nur mit Menschen, die deine Liste bearbeiten dürfen!',
        close: 'Schließen',
        showQR: 'QR-Code anzeigen',
    },
    copy: {
        empty: 'Diesen Wunsch zu einer neuen Liste hinzufügen',
        headline: 'In diese Liste kopieren:',
        login: 'Du hast schon ein Konto? <a href="/users/log_in">Einloggen</a> um deine Listen zu sehen.',
        newList: 'Neue Liste anlegen',
    },
    sort: {
        up: 'Oben',
        down: 'Unten',
    },
    mark_completed: {
        completed: 'Erledigt',
        mark_completed: 'Als erledigt markieren',
    },
    items: {
        show: 'Zeige alle Einträge',
        hide: 'Verstecke Einträge',
    },
    editor: {
        bold: 'Fett',
        italic: 'Kursiv',
        link: 'Link',
        url: 'URL',
        editLink: 'Link bearbeiten',
        removeUrl: 'Link entfernen',
    },
    import: {
        error: 'Leider laden Daten dieses Shops gerade nicht. Vielleicht blockiert dieser Shop unsere Webseite. Unsere <a target="_blank" rel="noopener noreferrer" href="/page/browser_extensions">Browser-Erweiterung</a> hilft in diesen Fällen.',
    },
    list: {
        wishes_one: '{{count}} Wunsch',
        wishes_other: '{{count}} Wünsche',
    },
};
