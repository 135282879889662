import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
    constructor(context) {
        super(context);
        this.isClaimed = false;
    }
    connect() {
        const element = this.element.querySelector('button');
        this.sharedList = this.element.closest('.shared-list--item');
        this.unclaimButton = this.sharedList.querySelector('.shared-list--item--admin-unclaim');
        if (this.unclaimButton) {
            this.unclaimButton.style.display = 'none';
        }
        if (this.sharedList.classList.contains('shared-list--item--claimed')) {
            this.sharedList.classList.remove('shared-list--item--claimed');
            this.sharedList.classList.add('shared-list--item--unclaimed');
            this.isClaimed = true;
        }
        element.addEventListener('click', this.onClick.bind(this));
    }
    onClick(event) {
        event.preventDefault();
        this.element.remove();
        if (this.isClaimed) {
            this.sharedList.classList.add('shared-list--item--claimed');
            this.sharedList.classList.remove('shared-list--item--unclaimed');
            if (this.unclaimButton) {
                this.unclaimButton.style.display = null;
            }
        }
    }
}
