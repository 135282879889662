export default {
    claim: {
        error: 'There was a problem while claiming this gift. Please reload the page.',
    },
    dialog: {
        close: 'Close this dialog',
        copy: 'Copy to clipboard',
    },
    delete: {
        title: 'Delete?',
        button: 'Delete',
    },
    common: {
        ok: 'OK',
        cancel: 'Cancel',
        close: 'Close',
    },
    cookieconsent: {
        header: 'Cookies used on the website!',
        message: 'This website uses cookies to improve your experience.',
        dismiss: 'Got it!',
        allow: 'Allow cookies',
        deny: 'Decline',
        link: 'Learn more',
        policy: 'Cookie Policy',
    },
    shareDialog: {
        shareList: 'Share wishlist',
        title: 'Share wishlist',
        sharingTitle: 'For guests',
        sharingExplanation: 'With this link everyone can see the list and reserve gifts. For everyone who wants to give something.',
        shareLink: 'Share link',
        copyLink: 'Copy link',
        editingTitle: 'For you',
        editingExplanation: 'With this link you can edit and delete the list. For everyone who should be able to edit the list. ⚠️',
        close: 'Close',
        showQR: 'Show QR code',
    },
    copy: {
        empty: 'Add this item to a new list',
        headline: 'Copy to this list:',
        login: 'Already have an account? <a href="/users/log_in">Login</a> to see your lists.',
        newList: 'Create new List',
    },
    sort: {
        up: 'Up',
        down: 'Down',
    },
    mark_completed: {
        completed: 'Completed',
        mark_completed: 'Mark as completed',
    },
    items: {
        show: 'Show all items',
        hide: 'Hide items',
    },
    editor: {
        bold: 'Bold',
        italic: 'Italic',
        link: 'Link',
        url: 'URL',
        editLink: 'Edit link',
        removeUrl: 'Remove link',
    },
    import: {
        error: 'We’re sorry but the data of this shop doesn’t work right now. Maybe it is blocking our Website. Using our <a target="_blank" rel="noopener noreferrer" href="/page/browser_extensions">browser extension</a> might help.',
    },
    list: {
        wishes_one: '{{count}} wish',
        wishes_other: '{{count}} wishes',
    },
};
