/**
 * Source:
 *  https://github.com/JuanMaRuiz/isbn-util/blob/master/src/validator.js
 */
const validLengths = {
    MIN: 10,
    MAX: 13,
};
const formatString = (string) => string.split('-').join('').replace(/\s/g, '');
const hasCorrectFormat = (value) => value.length === validLengths.MIN || value.length === validLengths.MAX;
const isValidIsbn = (isbn) => {
    let result = false;
    if (isbn.length === validLengths.MIN) {
        let weight = 10;
        let sum = 0;
        for (let i = 0; i < 9; i += 1) {
            const digit = parseInt(isbn[i], 10);
            sum += weight * digit;
            weight -= 1;
        }
        let check;
        const checkNumber = (11 - (sum % 11)) % 11;
        if (checkNumber === 10) {
            check = 'X';
        }
        else {
            check = checkNumber.toString();
        }
        result = check === isbn[isbn.length - 1].toUpperCase();
    }
    else {
        let sum = 0;
        for (let i = 0; i < 12; i += 1) {
            const digit = parseInt(isbn[i], 10);
            if (i % 2 === 1) {
                sum += 3 * digit;
            }
            else {
                sum += digit;
            }
        }
        const check = ((10 - (sum % 10)) % 10).toString();
        result = check === isbn[isbn.length - 1];
    }
    return result;
};
export const validateIsbn = (isbn) => {
    const sanitizedIsbn = formatString(isbn);
    let result = false;
    if (hasCorrectFormat(sanitizedIsbn)) {
        result = isValidIsbn(sanitizedIsbn);
    }
    return result;
};
