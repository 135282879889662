import {Controller} from '@hotwired/stimulus';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import {renderer} from '../helpers/renderer';
import A11yDialog from 'a11y-dialog';
import {i18next} from '../i18next.js';
import anime from 'animejs';

export default class extends Controller {
  click(event) {
    this.open(event);
  }

  open = (event) => {
    event.preventDefault();
    this.element.classList.add('btn--disabled');

    const source = this.element.getAttribute('href');
    fetch(source, {credentials: 'same-origin'}).then((response) => {
      if (response.status === 200) {
        response
          .text()
          .then((text) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(text, 'text/html');
            const form = doc.body.querySelector('.claim--new');
            return form;
          })
          .then((form) => {
            this.dialogElement = document.getElementsByTagName('body')[0].appendChild(this.dialogHtml(form));

            this.dialog = new A11yDialog(this.dialogElement);
            this.dialog.show();
            this.dialog.on('hide', this.close);
            const element = this.dialogElement.querySelector('.js-claim-cancel');
            if (element) {
              element.addEventListener('click', this.cancel);
            }
          });
      } else {
        this.dialogElement = document
          .getElementsByTagName('body')[0]
          .appendChild(this.dialogHtml(i18next.t('claim.error')));

        this.dialog = new A11yDialog(this.dialogElement);
        this.dialog.show();
        this.dialog.on('hide', this.close);
        const element = this.dialogElement.querySelector('.js-claim-cancel');
        if (element) {
          element.addEventListener('click', this.cancel);
        }
      }
    });
  };

  close = () => {
    this.element.classList.remove('btn--disabled');
    const animation = anime({
      targets: this.dialogElement,
      opacity: 0,
      easing: 'easeInOutQuad',
      duration: 150,
    });
    animation.finished.then(() => {
      this.dialogElement.remove();
      this.dialog.destroy();
    });
  };

  cancel = (event) => {
    event.preventDefault();
    this.close();
  };

  dialogHtml = (form) => {
    return (
      <div class="dialog" aria-labelledby="dialog-title">
        <div class="dialog-overlay" data-a11y-dialog-hide />
        <div role="dialog" class="dialog-content" aria-labelledby="dialog-title">
          <button type="button" class="dialog-close" onclick={this.close} aria-label={i18next.t('dialog.close')}>
            &times;
          </button>
          <div class="claim--dialog">{form}</div>
        </div>
      </div>
    );
  };
}
