// We need to import the CSS so that webpack will load it.
// The MiniCssExtractPlugin is used to separate it out into
// its own CSS file.
import '../css/app.module.scss';
import 'es7-object-polyfill';
import Bugsnag from '@bugsnag/js';
// webpack automatically bundles all modules in your
// entry points. Those entry points can be configured
// in "webpack.config.js".
//
// Import dependencies
//
// import "phoenix_html";
// Import local files
//
// Local files can be imported directly using relative paths, for example:
// import socket from "./socket"
import 'cookieconsent';
import { detect } from 'detect-browser';
import { i18next } from './i18next.js';
if (!window.location.host.startsWith('localhost')) {
    Bugsnag.start('4f8e93d0f36f32ddc10168ce44c6f884');
}
const browser = detect();
const isBot = () => {
    return browser.type === 'bot' || browser.type === 'bot-device';
};
document.addEventListener('DOMContentLoaded', () => {
    if (!isBot()) {
        window.cookieconsent.initialise({
            palette: {
                popup: {
                    background: '#000',
                },
                button: {
                    background: '#f1d600',
                },
            },
            content: {
                header: i18next.t('cookieconsent.header'),
                message: i18next.t('cookieconsent.message'),
                dismiss: i18next.t('cookieconsent.dismiss'),
                allow: i18next.t('cookieconsent.allow'),
                deny: i18next.t('cookieconsent.deny'),
                link: i18next.t('cookieconsent.link'),
                href: 'https://www.cookiesandyou.com',
                policy: i18next.t('cookieconsent.policy'),
            },
        });
    }
});
// let deferredPrompt;
window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    // deferredPrompt = e;
    // Update UI notify the user they can install the PWA
    // showInstallPromotion();
    // Optionally, send analytics event that PWA install promo was shown.
});
const registerServiceWorker = () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker
            .register('/sw.js', { scope: '/' })
            .then(() => {
            console.log('Service Worker registered successfully.');
        })
            .catch((error) => {
            console.log('Service Worker registration failed:', error);
        });
    }
};
const unregisterServiceWorker = () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
            // eslint-disable-next-line no-restricted-syntax
            for (const registration of registrations) {
                registration.unregister();
            }
        });
    }
};
if (browser.name === 'chrome' && browser.os === 'Android OS') {
    // We need the service worker on android chrome to make the app installable
    registerServiceWorker();
}
else {
    unregisterServiceWorker();
}
import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
const application = Application.start();
const context = require.context('./controllers', true, /\.js|\.tsx?$/);
application.load(definitionsFromContext(context));
