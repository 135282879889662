import {Controller} from '@hotwired/stimulus';
import {forEach} from '../helpers/forEach';

export default class extends Controller {
  connect = () => {
    this.element.setAttribute('novalidate', 'true');
    this.element.addEventListener('submit', (event) => {
      this.element.removeAttribute('novalidate');
      if (!this.element.checkValidity()) {
        event.preventDefault();
        setTimeout(() => {
          this.element.querySelector("button[type='submit']").click();
        }, 100);
      }
      const items = document.querySelectorAll('input, textarea');
      forEach(items, (element) => {
        element.classList.add('validation');
      });
    });

    const items = document.querySelectorAll('input, textarea');
    forEach(items, (element) => {
      element.addEventListener('blur', () => {
        element.classList.add('validation');
      });
    });
  };
}
