import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.hideIfScroll();
    window.onresize = () => {
      this.hideIfScroll();
    };
  }

  hideIfScroll = () => {
    if (document.body.scrollHeight < document.body.clientHeight * 1.5) {
      this.element.style.display = 'none';
    } else {
      this.element.style.display = null;
    }
  };
}
