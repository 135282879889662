import {disableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';

export default class Popup {
  constructor(element, popup, options = {}) {
    this.element = element;
    this.popup = popup;
    this.popupShown = true;
    this.popupClosed = options?.popupClosed;
    this.isClickInside = options?.isClickInside;
    setTimeout(() => {
      document.addEventListener('click', this.bodyClicked);
      window.addEventListener('resize', this.resizeWindow);
    }, 100);
    this.positionPopup();

    const mobileBreakpoint = 50 * parseFloat(getComputedStyle(document.documentElement).fontSize);

    if (window.innerWidth <= mobileBreakpoint) {
      disableBodyScroll(this.popup, {
        allowTouchMove: (el) => {
          while (el && el !== document.body) {
            if (el.getAttribute('body-scroll-lock-ignore') !== null) {
              return true;
            }

            el = el.parentElement;
          }
          return false;
        },
      });
    }
  }

  bodyClicked = (event) => {
    if (
      event.target !== this.popup &&
      this.popup &&
      !this.popup.contains(event.target) &&
      !(this.isClickInside && this.isClickInside(event))
    ) {
      event.preventDefault();
      this.removePopup();
    }
  };

  removePopup() {
    document.removeEventListener('click', this.bodyClicked);
    window.removeEventListener('resize', this.resizeWindow);
    clearAllBodyScrollLocks();
    this.popupShown = false;
    this.popup.remove();
    this.popup = null;
    if (this.popupClosed) {
      this.popupClosed();
    }
  }

  resizeWindow = () => {
    this.positionPopup();
  };

  positionPopup() {
    if (this.popup == null) {
      return;
    }

    const elementPosition = this.element.getBoundingClientRect();
    const cs = getComputedStyle(this.element);
    const cleanedPosition = {
      left: elementPosition.left + parseFloat(cs.paddingLeft),
      right: elementPosition.right + -parseFloat(cs.paddingRight),
      top: elementPosition.top + parseFloat(cs.paddingTop),
      bottom: elementPosition.bottom - parseFloat(cs.paddingBottom),
      width: elementPosition.width,
      height: elementPosition.height,
    };

    if (window.innerWidth < 800) {
      this.popup.style.top = 'auto';
      this.popup.style.left = '0';
      this.popup.style.right = '0';
      return;
    }

    if (cleanedPosition.left < 100) {
      this.openRight(cleanedPosition);
    } else if (window.innerWidth - cleanedPosition.right < 100) {
      this.openLeft(cleanedPosition);
    } else if (cleanedPosition.bottom < 300) {
      this.openBottom(cleanedPosition);
    } else {
      this.openTop(cleanedPosition);
    }
  }

  openRight(elementPosition) {
    const contextPosition = this.getStackingBoundingClientRect(this.element);

    this.popup.style.top = this.calculateTopPosition(elementPosition) + 'px';
    this.popup.style.left = elementPosition.left - contextPosition.left + elementPosition.width + 10 + 'px';
  }

  openLeft(elementPosition) {
    const contextPosition = this.getStackingBoundingClientRect(this.element);
    this.popup.style.top = this.calculateTopPosition(elementPosition) + 'px';
    this.popup.style.left =
      elementPosition.left - contextPosition.left - this.popup.getBoundingClientRect().width - 10 + 'px';
  }

  openBottom(elementPosition) {
    const contextPosition = this.getStackingBoundingClientRect(this.element);

    this.popup.style.top = elementPosition.top - contextPosition.top + elementPosition.height + 10 + 'px';
    this.popup.style.left = elementPosition.left - contextPosition.left - 125 + 10 + elementPosition.width / 2 + 'px';
  }

  openTop(elementPosition) {
    const contextPosition = this.getStackingBoundingClientRect(this.element);
    this.popup.style.top =
      elementPosition.top - contextPosition.top - 10 - this.popup.getBoundingClientRect().height + 'px';
    this.popup.style.left = elementPosition.left - contextPosition.left - 125 + elementPosition.width / 2 + 'px';
  }

  calculateTopPosition(elementPosition) {
    const contextPosition = this.getStackingBoundingClientRect(this.element);

    let top = elementPosition.top - contextPosition.top - this.popup.getBoundingClientRect().height / 2;

    if (elementPosition.top - this.popup.getBoundingClientRect().height / 2 < 0) {
      top = 10 - contextPosition.top;
    }

    if (top + this.popup.getBoundingClientRect().height + contextPosition.top > window.innerHeight) {
      top = window.innerHeight - this.popup.getBoundingClientRect().height - contextPosition.top - 10;
    }

    return top;
  }

  getStackingBoundingClientRect(element) {
    if (getComputedStyle(element).position === 'relative' || element.tagName === 'BODY') {
      return element.getBoundingClientRect();
    }

    return this.getStackingBoundingClientRect(element.parentNode);
  }
}
