import {Controller} from '@hotwired/stimulus';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import {renderer} from '../helpers/renderer';

export default class extends Controller {
  static targets = ['urlInput', 'priceField', 'priceExplanation'];

  connect() {
    this.urlInputTarget.addEventListener('change', this.urlChanged);
    this.urlInputTarget.addEventListener('keyup', this.urlChanged);
    this.checkIfAmazonUrl(this.urlInputTarget.value);
  }

  urlChanged = (event) => {
    this.checkIfAmazonUrl(event.target.value);
  };

  checkIfAmazonUrl = (value) => {
    if (this.isAmazonUrl(value)) {
      this.hidePrice();
    } else {
      this.showPrice();
    }
  };

  isAmazonUrl = (value) => {
    return value.match(/http(s)?:\/\/(.*\.)?(amazon|amzn)\.(com|at|ca|de|es|fr|it|to|co.jp|co.uk|eu).*/);
  };

  hidePrice = () => {
    this.priceFieldTarget.style.display = 'none';
    this.priceExplanationTarget.style.display = 'block';
  };

  showPrice = () => {
    this.priceFieldTarget.style.display = null;
    this.priceExplanationTarget.style.display = null;
  };
}
