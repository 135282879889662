import i18next from 'i18next';
import HttpApi from 'i18next-http-backend';
import i18nDE from './i18n/de/global';
import i18nEN from './i18n/en/global';

const i18n = i18next.use(HttpApi).init(
  {
    fallbackLng: 'en',
    whitelist: ['en', 'de'],
    lng: document.documentElement.lang,
    preload: [document.documentElement.lang],
    debug: false,
    detection: {
      order: ['htmlTag'],
      lookupQuerystring: 'lang',
    },
    resources: {
      en: {
        translation: i18nEN,
      },
      de: {
        translation: i18nDE,
      },
    },
  },
  (err, _t) => {
    if (err) {
      return console.error(err);
    }
    return null;
  }
);

export {i18n, i18next};
