import {Controller} from '@hotwired/stimulus';
import {forEach} from '../helpers/forEach';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import {renderer} from '../helpers/renderer';
import {i18next} from '../i18next.js';

import Sortable, {AutoScroll} from 'sortablejs/modular/sortable.core.esm.js';
Sortable.mount(new AutoScroll());

export default class extends Controller {
  connect() {
    this.initSorting();
  }

  initSorting = () => {
    this.sortable = Sortable.create(this.element, {
      delayOnTouchOnly: true,
      delay: 200,
      scrollSpeed: 60,
      scrollSensitivity: 60,
      onEnd: this.sortEnded,
      onChange: this.sortUpdated,
      filter: 'input, textarea',
      preventOnFilter: false,
    });

    this.updateSortButtons();
  };

  elementChanged = () => {
    this.sortable.destroy();
    this.sortable = null;
    this.initSorting();
  };

  sortEnded = (event) => {
    const listLength = this.element.getElementsByTagName('li').length;
    const newPosition = listLength - event.newIndex - 1;
    this.informBackend(event.item, newPosition);
  };

  informBackend = (element, newPosition) => {
    const targetUrl = element.getAttribute('data-sortable-url');

    const data = new FormData();
    data.append('_csrf_token', document.querySelector("input[name='_csrf_token']").getAttribute('value'));
    data.append('_method', 'patch');
    data.append('position', newPosition);

    fetch(targetUrl, {
      method: 'post',
      body: data,
      credentials: 'same-origin',
    });

    this.updateSortButtons();
  };

  sortUpdated = () => {
    this.updateSortButtons();
  };

  moveUp = (pos) => {
    const sortList = this.sortable.toArray();
    const listItems = this.element.getElementsByTagName('li');
    const newPosition = listItems.length - pos;
    this.sortable.sort(this.move(sortList, pos, pos - 1));
    this.informBackend(listItems[pos - 1], newPosition);
    this.updateSortButtons();
  };

  moveDown = (pos) => {
    const sortList = this.sortable.toArray();
    const listItems = this.element.getElementsByTagName('li');
    const newPosition = listItems.length - pos - 2;
    this.sortable.sort(this.move(sortList, pos, pos + 1));
    this.informBackend(listItems[pos + 1], newPosition);
    this.updateSortButtons();
  };

  move = (array, from, to) => {
    var element = array[from];
    array.splice(from, 1);
    array.splice(to, 0, element);
    return array;
  };

  updateSortButtons = () => {
    const items = document.querySelectorAll('.js-list--sort--buttons');
    forEach(items, (element, pos) => {
      element.innerHTML = '';
      element.appendChild(
        <div>
          {pos > 0 ? (
            <button class="list--items--buttons--left--button" onClick={() => this.moveUp(pos)}>
              <img src="/images/up.svg" alt={i18next.t('sort.up')} title={i18next.t('sort.up')} />
            </button>
          ) : (
            ''
          )}
          {pos < items.length - 1 ? (
            <button class="list--items--buttons--left--button" onClick={() => this.moveDown(pos)}>
              <img src="/images/down.svg" alt={i18next.t('sort.down')} title={i18next.t('sort.down')} />
            </button>
          ) : (
            ''
          )}
        </div>
      );
    });
  };
}
