import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
    constructor() {
        super(...arguments);
        this.trumpet = () => {
            this.audio.play();
        };
    }
    connect() {
        this.audio = new Audio('/sounds/elephant.mp3');
    }
}
