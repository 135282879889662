import { forEach } from './forEach';
const getShareButtons = () => {
    return Array.from(document.querySelectorAll('.list--header--buttons .btn--small,.list--footer--buttons .btn--small'));
};
export const disableShareButton = () => {
    forEach(getShareButtons(), (button) => {
        button.classList.add('btn--disabled');
    });
};
export const enableShareButton = () => {
    forEach(getShareButtons(), (button) => {
        button.classList.remove('btn--disabled');
    });
};
