import {Controller} from '@hotwired/stimulus';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import {renderer} from '../helpers/renderer';
import {i18next} from '../i18next.js';
import {shareOrCopyUrl, flashCopyHint, copyToClipboard} from '../helpers/clipboard';
import {forEach} from '../helpers/forEach';

export default class extends Controller {
  static targets = ['input', 'copyHint'];

  connect() {
    const elements = this.element.querySelectorAll('.share--action');

    forEach(elements, (el) => el.remove());

    this.element.appendChild(this.renderCopyButton());
    this.inputTarget.onclick = (event) => {
      event.preventDefault();
      copyToClipboard(this.inputTarget, this.inputTarget.value, () => {
        flashCopyHint(this.copyHintTarget);
      });
    };
  }

  click = () => {
    shareOrCopyUrl(this.inputTarget, this.inputTarget.value, (clipboardUsed) => {
      if (clipboardUsed) {
        flashCopyHint(this.copyHintTarget);
      }
    });
  };

  renderCopyButton() {
    return (
      <div class="share--action">
        <div data-target="share.copyHint" class="share--copy">
          {i18next.t('dialog.copy')} ✓
        </div>

        <button type="button" class="btn--small" onclick={this.click}>
          {navigator.share ? this.data.get('button') : this.data.get('button-alternative')}
        </button>
      </div>
    );
  }
}
