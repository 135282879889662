import { Controller } from '@hotwired/stimulus';
import { i18next } from '../i18next.js';
export default class default_1 extends Controller {
    constructor() {
        super(...arguments);
        this.itemTargetConnected = () => {
            this.updateCounter();
        };
        this.itemTargetDisconnected = () => {
            this.updateCounter();
        };
        this.updateCounter = () => {
            if (this.itemTargets.length > 0) {
                this.viewTarget.innerText = i18next.t(this.i18nKeyValue, { count: this.itemTargets.length });
            }
            else {
                this.viewTarget.innerText = '';
            }
        };
    }
    connect() {
        this.updateCounter();
    }
}
default_1.targets = ['view', 'item'];
default_1.values = {
    i18nKey: String,
};
