import anime from 'animejs';

export const copyToClipboard = (element, url, callback) => {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(url);
  } else {
    element.setSelectionRange(0, element.value.length);
    document.execCommand('copy');
    element.setSelectionRange(0, 0);
  }
  callback(true);
};

export const flashCopyHint = (element) => {
  element.style.display = 'flex';

  const animation = anime.timeline({
    targets: element,
    duration: 500,
    easing: 'easeInOutQuad',
  });

  animation.add({
    opacity: 1,
    duration: 200,
  });
  animation.add({
    opacity: 0,
    delay: 500,
    duration: 500,
  });
  animation.finished.then(() => {
    element.style.display = 'none';
  });
};

export const shareOrCopyUrl = (element, url, callback) => {
  if (navigator.share) {
    navigator
      .share({
        url: url,
      })
      .then(() => {
        callback(false);
      })
      .catch((error) => {
        if (
          error.name !== 'AbortError' ||
          error.message === 'Internal error: could not connect to Web Share interface.'
        ) {
          copyToClipboard(element, url, callback);
        }
      });
  } else {
    copyToClipboard(element, url, callback);
  }
};
