import {Controller} from '@hotwired/stimulus';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import {renderer} from '../helpers/renderer';
import A11yDialog from 'a11y-dialog';
import {i18next} from '../i18next.js';
import anime from 'animejs';
import {shareOrCopyUrl, flashCopyHint, copyToClipboard} from '../helpers/clipboard';
import {replaceChildren} from '../helpers/replaceChildren';

export default class extends Controller {
  static targets = ['editLink', 'shareLink'];

  static values = {onlyIcon: String};

  connect = () => {
    this.editLink = this.editLinkTarget.href;
    this.shareLink = this.shareLinkTarget.href;
    this.sharingVisible = false;
    this.animating = false;
    replaceChildren(this.element, this.shareHTML());
  };

  focusButton = () => {
    this.dialogElement.querySelector('.btn--small').focus();
  };

  animateCopyInfo = (element) => {
    const copyDisplay = element.querySelector('.share--dialog--copy');
    flashCopyHint(copyDisplay);
  };

  inputClicked = (event) => {
    event.preventDefault();
    copyToClipboard(event.target.parentNode.querySelector('.share--dialog--url'), event.target.value, () => {
      this.animateCopyInfo(event.target.parentNode);
    });
  };

  click = (event, inputFieldClass, url) => {
    event.preventDefault();
    const inputField = this.dialogElement.querySelector(inputFieldClass);
    shareOrCopyUrl(inputField, url, (clipboardUsed) => {
      if (clipboardUsed) {
        this.animateCopyInfo(inputField.parentNode);
        setTimeout(this.close, 1000);
      } else {
        this.close();
      }
    });
  };

  openDialog = (event) => {
    event && event.preventDefault();

    this.dialogElement = document.querySelector('body').appendChild(this.dialogHtml());

    this.dialog = new A11yDialog(this.dialogElement);
    this.dialog.show();
    this.dialog.on('hide', this.close);
    this.focusButton();

    this.shareDivHeight = this.getElementHeight(this.dialogElement.querySelector('.share--dialog--sharing'));
    this.dialogElement.querySelector('.share--dialog--sharing--chevron').style.transform = 'rotate(90deg)';
    this.editingDivHeight = this.getElementHeight(this.dialogElement.querySelector('.share--dialog--editing'));
  };

  close = () => {
    const animation = anime({
      targets: this.dialogElement,
      opacity: 0,
      easing: 'easeInOutQuad',
      duration: 300,
    });
    animation.finished.then(() => {
      this.dialogElement.remove();
      this.dialog.destroy();
    });
  };

  shareHTML = () => {
    if (this.onlyIconValue === 'true') {
      return (
        <span>
          <button type="button" class="share--dialog--button--with-icon" onClick={this.openDialog}>
            <img
              src="/images/share_arrow.svg"
              aria-hidden="true"
              alt={i18next.t('shareDialog.shareList')}
              title={i18next.t('shareDialog.shareList')}
            />
          </button>
        </span>
      );
    }

    return (
      <span>
        <button type="button" class="share--dialog--button btn--small" onClick={this.openDialog}>
          <img class="share--dialog--arrow" src="/images/share_arrow_white.svg" aria-hidden="true" />
          {i18next.t('shareDialog.shareList')}
        </button>
      </span>
    );
  };

  getElementHeight = (target) => {
    const preStyle = target.style;

    target.style.height = 'auto';
    target.style.display = 'block';
    target.style.boxSizing = 'border-box';
    const compStyles = window.getComputedStyle(target);
    const result = {
      height: compStyles.getPropertyValue('height'),
    };

    target.style = preStyle;

    return result.height;
  };

  toggleVisible = (event) => {
    event.preventDefault();
    if (this.animating) {
      return;
    }

    this.animating = true;
    this.sharingVisible = !this.sharingVisible;

    const sharingDiv = this.dialogElement.querySelector('.share--dialog--sharing');
    const editingDiv = this.dialogElement.querySelector('.share--dialog--editing');
    if (this.sharingVisible) {
      anime({
        targets: sharingDiv,
        opacity: 0,
        height: 0,
        easing: 'easeInOutQuad',
        duration: 300,
      }).finished.then(() => {
        this.animating = false;
      });
      anime({
        targets: editingDiv,
        opacity: 1,
        height: this.editingDivHeight,
        easing: 'easeInOutQuad',
        duration: 300,
      });
      anime({
        targets: ['.share--dialog--sharing--chevron'],
        rotate: 0,
        easing: 'linear',
        duration: 300,
      });
      anime({
        targets: ['.share--dialog--editing--chevron'],
        rotate: 90,
        easing: 'linear',
        duration: 300,
      });
    } else {
      anime({
        targets: sharingDiv,
        opacity: 1,
        height: this.shareDivHeight,
        easing: 'easeInOutQuad',
        duration: 300,
      });
      anime({
        targets: editingDiv,
        opacity: 0,
        height: 0,
        easing: 'easeInOutQuad',
        duration: 300,
      }).finished.then(() => {
        this.animating = false;
      });
      anime({
        targets: ['.share--dialog--sharing--chevron'],
        rotate: 90,
        easing: 'linear',
        duration: 300,
      });
      anime({
        targets: ['.share--dialog--editing--chevron'],
        rotate: 0,
        easing: 'linear',
        duration: 300,
      });
    }
  };

  dialogHtml = () => {
    const element = document.createElement('span');
    element.innerHTML = i18next.t('shareDialog.editingExplanation').replace('\n', '<br />');

    return (
      <div class="dialog" aria-labelledby="dialog-title">
        <div class="dialog-overlay" data-a11y-dialog-hide />
        <div role="dialog" class="dialog-content">
          <button type="button" class="dialog-close" data-a11y-dialog-hide aria-label={i18next.t('dialog.close')}>
            &times;
          </button>

          <h1 id="dialog-title">{i18next.t('shareDialog.title')}</h1>

          <button type="button" class="share--dialog--subtitle" onClick={this.toggleVisible}>
            <img class="share--dialog--chevron share--dialog--sharing--chevron" src="/images/chevron_right.svg" />
            {i18next.t('shareDialog.sharingTitle')}
          </button>

          <div class="share--dialog--sharing">
            <p class="share--dialog--explanation">{i18next.t('shareDialog.sharingExplanation')}</p>

            <div class="flow-form share--dialog--input">
              <input
                class="share--dialog--url share--dialog--share-url"
                readonly
                value={this.shareLink}
                onclick={this.inputClicked}
              />
              <div class="share--dialog--copy share--dialog--share-copy">{i18next.t('dialog.copy')} ✓</div>
            </div>

            <div class="share--dialog--action">
              <button
                type="button"
                class="btn--small"
                onClick={(event) => {
                  this.click(event, '.share--dialog--share-url', this.shareLink);
                }}>
                {navigator.share ? i18next.t('shareDialog.shareLink') : i18next.t('shareDialog.copyLink')}
              </button>
              <a href={`${this.shareLink}/qrcode?return=${encodeURIComponent(window.location.pathname)}`}>
                {i18next.t('shareDialog.showQR')}
              </a>
            </div>
          </div>

          <button type="button" class="share--dialog--subtitle" onClick={this.toggleVisible}>
            <img class="share--dialog--chevron share--dialog--editing--chevron" src="/images/chevron_right.svg" />
            {i18next.t('shareDialog.editingTitle')}
          </button>

          <div class="share--dialog--editing">
            <p class="share--dialog--explanation">{element}</p>

            <div class="flow-form share--dialog--input">
              <input
                class="share--dialog--url share--dialog--edit-url"
                readonly
                value={this.editLink}
                onclick={this.inputClicked}
              />
              <div class="share--dialog--copy share--dialog--edit-copy">{i18next.t('dialog.copy')} ✓</div>
            </div>

            <div class="share--dialog--action">
              <button
                type="button"
                class="btn--small"
                onclick={(event) => {
                  this.click(event, '.share--dialog--edit-url', this.editLink);
                }}>
                {navigator.share ? i18next.t('shareDialog.shareLink') : i18next.t('shareDialog.copyLink')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
