import {Controller} from '@hotwired/stimulus';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import {renderer} from '../helpers/renderer';
import A11yDialog from 'a11y-dialog';
import anime from 'animejs';
import {i18next} from '../i18next.js';

export default class extends Controller {
  claimCount = 0;

  connect() {
    this.element.addEventListener('click', this.listClaimsClicked);
  }

  listClaimsClicked = (event) => {
    event.preventDefault();

    fetch(this.element.getAttribute('href'), {
      credentials: 'same-origin',
    })
      .then((response) => response.text())
      .then((text) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, 'text/html');
        const claims = doc.body.querySelector('main .list-claims');
        this.claimCount = claims.querySelectorAll('tr').length;
        if (claims) {
          this.dialogElement = document.getElementsByTagName('body')[0].appendChild(this.dialogHtml(claims));

          this.dialog = new A11yDialog(this.dialogElement);
          this.dialog.show();
          this.dialog.on('hide', this.close);
        }
      });
  };

  close = () => {
    const claimCount = document.body.querySelectorAll('#list-claims-dialog tr').length;
    let refreshPage = false;

    if (this.claimCount !== claimCount) {
      refreshPage = true;
    }

    this.claimCount = 0;
    const animation = anime({
      targets: this.dialogElement,
      opacity: 0,
      easing: 'easeInOutQuad',
      duration: 300,
    });
    animation.finished.then(() => {
      this.dialogElement.remove();
      this.dialog.destroy();
      if (refreshPage) {
        window.location.reload();
      }
    });
  };

  dialogHtml = (claims) => {
    return (
      <div id="list-claims-dialog" class="dialog" aria-labelledby="dialog-title">
        <div class="dialog-overlay" data-a11y-dialog-hide />
        <div role="dialog" class="dialog-content">
          <button type="button" class="dialog-close" data-a11y-dialog-hide aria-label={i18next.t('dialog.close')}>
            &times;
          </button>

          <h1 id="dialog-title">{this.data.get('title')}</h1>

          <div class="dialog-list-claims--body">{claims}</div>

          <div class="delete-buttons">
            <button type="button" class="btn--border" onclick={this.close}>
              {i18next.t('common.close')}
            </button>
          </div>
        </div>
      </div>
    );
  };
}
