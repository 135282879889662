import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
    stateChange(event) {
        if (event.target.checked) {
            this.element.classList.add('open');
        }
        else {
            this.element.classList.remove('open');
        }
    }
}
