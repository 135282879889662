import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
    connect() {
        const element = this.element;
        element.style.boxSizing = 'border-box';
        element.addEventListener('input', () => {
            this.resizeElement();
        });
        const fontSize = window.getComputedStyle(element, null).getPropertyValue('font-size');
        const paddingTop = window.getComputedStyle(element, null).getPropertyValue('padding-top');
        const paddingBottom = window.getComputedStyle(element, null).getPropertyValue('padding-bottom');
        this.minHeight = parseFloat(fontSize) * 2 + parseFloat(paddingTop) + parseFloat(paddingBottom);
        this.resizeElement();
    }
    resizeElement() {
        const element = this.element;
        const offset = element.offsetHeight - element.clientHeight;
        element.style.height = 'auto';
        element.style.height = Math.max(this.minHeight, this.element.scrollHeight + offset) + 'px';
    }
}
