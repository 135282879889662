import {Controller} from '@hotwired/stimulus';
import exif2css from '../helpers/exif2css';
import exifOrientation from '../helpers/exif';

export default class extends Controller {
  static targets = ['img', 'input'];

  connect() {
    this.element.addEventListener('drop', (event) => {
      event.preventDefault();
      this.inputTarget.files = event.dataTransfer.files;
      this.updatePreview();
    });
  }

  changeFile = (event) => {
    if (event.target.files && event.target.files[0]) {
      this.updatePreview();
    }
  };

  updatePreview = () => {
    const reader = new FileReader();
    reader.onload = (event) => {
      this.imgTarget.src = event.target.result;
      const orientation = exifOrientation(event.target.result);
      const css = exif2css(orientation);

      this.imgTarget.style = '';

      if (css.transform) {
        this.imgTarget.style.transform = css.transform;
      }
      if (css['transform-origin']) {
        this.imgTarget.style['transform-origin'] = css['transform-origin'];
      }
    };
    reader.readAsDataURL(this.inputTarget.files[0]);
  };
}
