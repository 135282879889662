import { Controller } from '@hotwired/stimulus';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { renderer } from '../helpers/renderer';
import { i18next } from '../i18next.js';
import { random } from '../helpers/random';
import { replaceChildren } from '../helpers/replaceChildren';
export default class default_1 extends Controller {
    constructor() {
        super(...arguments);
        this.toggle = (event) => {
            if (event.target.checked) {
                this.completeEntry();
            }
            else {
                this.uncompleteEntry();
            }
        };
        this.pingAPI = (method) => {
            const data = new FormData();
            data.append('_method', method);
            data.append('_csrf_token', document.querySelector('meta[name="csrf-token"]').getAttribute('content'));
            const currentItem = this.element.closest('.list--item');
            fetch(this.data.get('path'), {
                method: 'post',
                body: data,
                credentials: 'same-origin',
            })
                .then((response) => response.text())
                .then((text) => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(text, 'text/html');
                const id = currentItem.getAttribute('id');
                const newElement = doc.querySelector(`#${id}`);
                if (newElement) {
                    currentItem.innerHTML = newElement.innerHTML;
                }
            });
        };
        this.completeEntry = () => {
            this.pingAPI('post');
        };
        this.uncompleteEntry = () => {
            this.pingAPI('delete');
        };
    }
    connect() {
        replaceChildren(this.element, this.renderCheckbox());
    }
    renderCheckbox() {
        const id = `mark-completed-${random(1, 100000)}`;
        const completed = this.data.get('completed-at') !== '';
        return (renderer.create("div", { class: "mark-completed" },
            renderer.create("input", { type: "checkbox", id: id, onclick: this.toggle, checked: completed }),
            renderer.create("label", { for: id }, completed ? i18next.t('mark_completed.completed') : i18next.t('mark_completed.mark_completed'))));
    }
}
default_1.targets = ['input', 'copyHint'];
