import {Controller} from '@hotwired/stimulus';
import {Editor} from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import {renderer} from '../helpers/renderer';
import Link from '@tiptap/extension-link';
import A11yDialog from 'a11y-dialog';
import {i18next} from '../i18next.js';
import anime from 'animejs';
export default class extends Controller {
  static targets = ['inputField', 'htmlEditor'];

  editor = null;

  connect() {
    this.inputFieldTarget.style.display = 'none';
    this.htmlEditorTarget.style.display = 'inline-block';
    this.htmlEditorTarget.style.marginTop = '2rem';
    this.htmlEditorTarget.classList.add('html-editor');
    const content = this.htmlEditorTarget.innerHTML;
    this.htmlEditorTarget.innerHTML = '';

    this.editor = new Editor({
      element: this.htmlEditorTarget,
      extensions: [
        StarterKit,
        Link.configure({
          openOnClick: false,
        }),
      ],
      content: content,
      onCreate: this.updateField,
      onUpdate: this.updateField,
    });
    this.renderButtons();
  }

  updateField = ({editor}) => {
    const html = editor.getHTML();
    this.inputFieldTarget.value = html;
  };

  renderButtons() {
    this.htmlEditorTarget.prepend(
      <div class="html-editor--buttons">
        <button
          type="button"
          onClick={this.toggleBold}
          class="html-editor--buttons--bold"
          title={i18next.t('editor.bold')}
          aria-label={i18next.t('editor.bold')}>
          B
        </button>
        <button
          type="button"
          onClick={this.toggleItalic}
          class="html-editor--buttons--italic"
          title={i18next.t('editor.italic')}
          aria-label={i18next.t('editor.italic')}>
          I
        </button>
        <button type="button" onClick={this.editLink}>
          <img
            src="/images/link.svg"
            alt={i18next.t('editor.link')}
            title={i18next.t('editor.link')}
            aria-label={i18next.t('editor.link')}
          />
        </button>
      </div>
    );
  }

  toggleBold = () => {
    this.editor.chain().focus().toggleBold().run();
  };

  toggleItalic = () => {
    this.editor.chain().focus().toggleItalic().run();
  };

  editLink = () => {
    this.dialogElement = document.getElementsByTagName('body')[0].appendChild(this.dialogHtml());

    this.dialog = new A11yDialog(this.dialogElement);
    this.dialog.show();
    this.dialog.on('hide', this.close);
  };

  close = () => {
    const animation = anime({
      targets: this.dialogElement,
      opacity: 0,
      easing: 'easeInOutQuad',
      duration: 150,
    });
    animation.finished.then(() => {
      this.dialogElement.remove();
      this.dialog.destroy();
    });
  };

  setLink = (event) => {
    event.preventDefault();
    const url = event.target.url.value;

    if (url && url.length > 0) {
      this.editor.chain().focus().extendMarkRange('link').setLink({href: url}).run();
    } else {
      this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
    }

    this.close();
    return true;
  };

  removeLink = () => {
    this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
    this.close();
  };

  dialogHtml = () => {
    const previousUrl = this.editor.getAttributes('link').href;

    return (
      <div class="dialog" aria-labelledby="dialog-title">
        <div class="dialog-overlay" data-a11y-dialog-hide />
        <div role="dialog" class="dialog-content" aria-labelledby="dialog-title">
          <button type="button" class="dialog-close" onclick={this.close} aria-label={i18next.t('dialog.close')}>
            &times;
          </button>
          <div class="claim--dialog">
            <h1 id="dialog-title" class="dialog-title">
              {i18next.t('editor.editLink')}
            </h1>
            <form onSubmit={this.setLink} class="flow-form">
              <div class="form-group">
                <input
                  class="form-control"
                  type="url"
                  value={previousUrl || ''}
                  name="url"
                  placeholder={i18next.t('editor.url')}
                />
                <label for="url">{i18next.t('editor.url')}</label>
              </div>

              <div class="html-editor--footer">
                <div class="html-editor--actions-start">
                  {(previousUrl && (
                    <button type="button" class="btn--link" onClick={this.removeLink}>
                      {i18next.t('editor.removeUrl')}
                    </button>
                  )) ||
                    ''}
                </div>
                <div class="html-editor--actions-end">
                  <button type="submit" class="btn--small">
                    {i18next.t('common.ok')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };
}
