import { Controller } from '@hotwired/stimulus';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { renderer } from '../helpers/renderer';
import { i18next } from '../i18next.js';
export default class extends Controller {
    constructor() {
        super(...arguments);
        this.getCookieName = () => {
            return `hint${this.data.get('id')}`;
        };
        this.hideDialog = (event) => {
            event.preventDefault();
            document.cookie = `${this.getCookieName()}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
            this.element.remove();
        };
        this.renderCloseButton = () => {
            return (renderer.create("div", { class: "js-hint-dialog--close" },
                renderer.create("button", { "aria-label": i18next.t('dialog.close'), onclick: this.hideDialog }, "x")));
        };
    }
    connect() {
        if (!document.cookie.split('; ').find((row) => row.startsWith(this.getCookieName()))) {
            this.element.insertAdjacentElement('afterbegin', this.renderCloseButton());
        }
        else {
            this.element.remove();
        }
    }
}
