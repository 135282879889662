import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
    constructor() {
        super(...arguments);
        this.connect = () => {
            const sourceElement = document.getElementById(this.data.get('source'));
            sourceElement.addEventListener('change', this.updateState);
            this.setStateBy(sourceElement);
        };
        this.updateState = (event) => {
            this.setStateBy(event.target);
        };
        this.setStateBy = (sourceElement) => {
            if (sourceElement.checked) {
                this.element.removeAttribute('disabled');
            }
            else {
                this.element.setAttribute('disabled', 'disabled');
            }
        };
    }
}
