import {Controller} from '@hotwired/stimulus';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import {renderer} from '../helpers/renderer';
import anime from 'animejs';
import A11yDialog from 'a11y-dialog';
import {forEach} from '../helpers/forEach';
import {i18next} from '../i18next.js';
import Spinner from '../components/spinner';
import Popup from '../helpers/popup';
import {raw} from '../helpers/raw';

export default class extends Controller {
  popup = null;

  popupController = null;

  htmlContent = null;

  htmlContentFetchDate = null;

  dialog = null;

  connect() {
    this.element.addEventListener('click', (event) => {
      event.preventDefault();
      this.togglePopup();
    });
  }

  togglePopup() {
    if (this.popupController) {
      this.popupController.removePopup();
    } else {
      this.showPopup();
    }
  }

  showPopup() {
    this.popup = this.element.insertAdjacentElement(
      'afterend',
      <div class="popup copy--popup">
        <div class="copy--popup--spinner">
          <Spinner />
        </div>
      </div>
    );

    this.fillPopupContent();
    this.popupController = new Popup(this.element, this.popup, {
      popupClosed: this.removePopup,
      isClickInside: this.clickInsideOfPopup,
    });
  }

  clickInsideOfPopup = (event) => {
    return this.dialogElement && this.dialogElement.contains(event.target);
  };

  removePopup = () => {
    this.popupController = null;
  };

  removeDialog = () => {
    if (this.popupController) {
      this.popupController.removePopup();
    }
  };

  fillPopupContent() {
    const targetUrl = this.element.getAttribute('href');

    if (
      this.htmlContent &&
      this.htmlContentFetchDate &&
      new Date().getTime() - this.htmlContentFetchDate.getTime() < 30000
    ) {
      this.renderPopup();
    } else {
      fetch(targetUrl, {
        method: 'GET',
        credentials: 'same-origin',
      }).then((result) => {
        if (result.status === 200) {
          result.text().then((text) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(text, 'text/html');

            this.htmlContent = doc;
            this.htmlContentFetchDate = new Date();
            this.renderPopup();
          });
        }
      });
    }
  }

  renderPopup = () => {
    this.popup.innerHTML = '';

    const items = this.htmlContent.querySelectorAll('.form-select--multiline option');
    let listEntries = [];
    forEach(items, (element) => {
      listEntries.push(
        <a
          href="/"
          class="copy--popup--list--entry"
          onClick={(event) => {
            this.addToList(event, element.getAttribute('value'));
          }}>
          {element.textContent}
        </a>
      );
    });

    const loginText = this.htmlContent.querySelector('.js-please-login');

    const renderListEntries = () => {
      return listEntries.length === 0 ? (
        <p class="copy--popup--empty">{i18next.t('copy.empty')}</p>
      ) : (
        <div class="copy--popup--list" body-scroll-lock-ignore="true">
          {listEntries}
        </div>
      );
    };

    this.popup.appendChild(
      <div class="copy--popup--wrapper">
        <div class="copy--popup--headline">
          {i18next.t('copy.headline')}

          <button type="button" class="popup-close" aria-label={i18next.t('dialog.close')} onClick={this.removeDialog}>
            &times;
          </button>
        </div>

        {loginText === null ? (
          renderListEntries()
        ) : (
          <div class="copy--popup--login">
            <p>{raw(i18next.t('copy.login'))}</p>
          </div>
        )}

        <div class="copy--popup--create-list">
          <button type="button" class="copy--popup--create-list--button" onClick={this.showCreateListDialog}>
            <span class="copy--popup--create-list--plus" />
            <span>{i18next.t('copy.newList')}</span>
          </button>
        </div>
      </div>
    );
    if (this.popupController) {
      this.popupController.positionPopup();
    }
  };

  addToList(event, listId) {
    event.preventDefault();
    this.popup.appendChild(
      <div class="copy--popup--waiting">
        <Spinner />
      </div>
    );

    const copyForm = this.htmlContent.querySelector('.js-copy-form');

    const targetUrl = copyForm.getAttribute('action');
    const listEntryId = copyForm.querySelector('input[name="list_entry_id"]');

    const data = new FormData();
    data.append('_csrf_token', this.htmlContent.querySelector("input[name='_csrf_token']").getAttribute('value'));
    data.append('copy_to_list_id', listId);

    if (listEntryId) {
      data.append('list_entry_id', listEntryId.getAttribute('value'));
    }

    fetch(targetUrl, {
      method: 'post',
      body: data,
      credentials: 'same-origin',
    }).then((response) => {
      if (response.status === 200) {
        this.animateSuccess();
      } else {
        this.removePopup();
      }
    });
  }

  animateSuccess() {
    const element = this.popup.appendChild(<div class="copy--popup--success">✓</div>);
    anime({
      targets: element,
      easing: 'easeInOutQuad',
      duration: 300,
      opacity: 1,
    });
    const animation = anime({
      targets: this.popup,
      easing: 'easeInOutQuad',
      duration: 300,
      opacity: 0,
      delay: 600,
    });
    animation.finished.then(() => {
      this.removeDialog();
    });
  }

  dialogHtml = () => {
    const createListHeader = this.htmlContent.querySelector('.js-create-list-header').textContent;
    const createList = this.htmlContent.querySelector('.js-create-list-form').cloneNode(true);

    return (
      <div id="copy-dialog" class="dialog" aria-labelledby="dialog-title">
        <div class="dialog-overlay" data-a11y-dialog-hide />
        <div role="dialog" class="dialog-content copy--dialog">
          <button type="button" class="dialog-close" data-a11y-dialog-hide aria-label={i18next.t('dialog.close')}>
            &times;
          </button>

          <h1 id="dialog-title">{createListHeader}</h1>

          {createList}
        </div>
      </div>
    );
  };

  showCreateListDialog = () => {
    this.dialogElement = document.getElementsByTagName('body')[0].appendChild(this.dialogHtml());

    this.dialog = new A11yDialog(this.dialogElement);
    this.dialog.show();
    this.dialog.on('hide', this.closeDialog);
    this.dialogElement.querySelector("input[type='text']").focus();

    this.dialogElement.querySelector('.js-create-list-form').addEventListener('submit', this.createListSubmit);
  };

  createListSubmit = (event) => {
    event.preventDefault();

    this.dialogElement.querySelector('.copy--dialog').append(
      <div class="copy--dialog--spinner">
        <Spinner />
      </div>
    );

    fetch(event.target.action, {
      method: 'POST',
      body: new FormData(event.target),
      credentials: 'same-origin',
    }).then((resp) => {
      if (resp.redirected) {
        this.dialogElement.querySelector('.copy--dialog--spinner').remove();
        const element = this.dialogElement.querySelector('.copy--dialog').appendChild(
          <div style={{opacity: 0}} class="copy--popup--success">
            ✓
          </div>
        );
        anime({
          targets: element,
          easing: 'easeInOutQuad',
          duration: 300,
          opacity: 1,
        });
        const animation = anime({
          targets: this.popup,
          easing: 'easeInOutQuad',
          duration: 300,
          opacity: 0,
          delay: 600,
        });
        animation.finished.then(() => {
          this.removeDialog();
          this.closeDialog();
        });
      }
    });
  };

  closeDialog = () => {
    this.dialogElement.remove();
    this.dialog.destroy();
    if (this.popupController) {
      this.popupController.removePopup();
    }
  };
}
