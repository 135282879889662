/* Cut down version of https://github.com/exif-js/exif-js
 * only looking for orientation EXIF data. MIT. */

function readEXIFData(file, start) {
  let str = '';
  for (let n = start; n < start + 4; n += 1) {
    str += String.fromCharCode(file.getUint8(n));
  }
  if (str !== 'Exif') {
    return false;
  }

  let bigEnd;
  let tiffOffset = start + 6;

  // test for TIFF validity and endianness
  if (file.getUint16(tiffOffset) === 0x4949) {
    bigEnd = false;
  } else if (file.getUint16(tiffOffset) === 0x4d4d) {
    bigEnd = true;
  } else {
    return false;
  }

  if (file.getUint16(tiffOffset + 2, !bigEnd) !== 0x002a) {
    return false;
  }

  let firstIFDOffset = file.getUint32(tiffOffset + 4, !bigEnd);
  if (firstIFDOffset < 0x00000008) {
    return false;
  }

  let dirStart = tiffOffset + firstIFDOffset;
  let entries = file.getUint16(dirStart, !bigEnd);
  let entryOffset;
  let i;

  for (i = 0; i < entries; i += 1) {
    entryOffset = dirStart + i * 12 + 2;
    if (file.getUint16(entryOffset, !bigEnd) === 0x0112) {
      return parseInt(file.getUint16(entryOffset + 8, !bigEnd), 10);
    }
  }
  return false;
}

function getData(imgSrc) {
  let base64 = imgSrc.replace(/^data:([^;]+);base64,/gim, '');
  let binary = atob(base64);
  let len = binary.length;
  let file = new ArrayBuffer(len);
  let view = new Uint8Array(file);
  for (let i = 0; i < len; i += 1) {
    view[i] = binary.charCodeAt(i);
  }

  let dataView = new DataView(file);
  if (dataView.getUint8(0) !== 0xff || dataView.getUint8(1) !== 0xd8) {
    return false; // not a valid jpeg
  }

  let offset = 2;
  let length = file.byteLength;
  let marker;

  while (offset < length) {
    if (dataView.getUint8(offset) !== 0xff) {
      return false; // not a valid marker, something is wrong
    }

    marker = dataView.getUint8(offset + 1);

    // we could implement handling for other markers here,
    // but we're only looking for 0xFFE1 for EXIF data
    if (marker === 225) {
      return readEXIFData(dataView, offset + 4);
      // offset += 2 + file.getShortAt(offset+2, true);
    }
    offset += 2 + dataView.getUint16(offset + 2);
  }
  return null;
}

export default getData;
